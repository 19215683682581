@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
  }
}
