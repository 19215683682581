@use '@angular/material' as mat;
@use 'sass:map';

@mixin sizes-and-spacing() {
  .mat-mdc-chip {
    display: inline-grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    margin-right: 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .mat-chip-remove.mat-icon {
      cursor: pointer;
      margin-left: 6px;
    }

    &.mat-mdc-standard-chip {
      min-height: 36px;

      &.mat-chip-with-trailing-icon {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }

    &.na-small {
      padding: 0;

      .mat-chip-remove.mat-icon {
        margin-left: 5px;
      }

      &.mdc-evolution-chip {
        margin: 0;
      }

      &.mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 18px;
      }

      &.mdc-evolution-chip-set.mdc-evolution-chip {
        margin: 0;
      }

      &.mat-mdc-standard-chip {
        min-height: 18px;

        &.mat-mdc-chip-with-trailing-icon {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent: map.get($color-config, 'accent');
  $primary: map.get($color-config, 'primary');
  $border-color: mat.m2-get-color-from-palette($accent, 'light-grey');

  .mat-mdc-chip {
    border-radius: 18px;
    //FIXME Originally this style was also in the old components, but was not applied correctly to overwrite a standard material styling
    //tell Hannah and reactivate this overwrite in case she decides that we need it
    // border: 1px solid $border-color;

    &.mat-mdc-standard-chip {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
      color: mat.m2-get-color-from-palette($accent, 'light-grey-50-contrast');
      &:not(.mdc-evolution-chip--disabled) {
        background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
        color: mat.m2-get-color-from-palette($accent, 'light-grey-50-contrast');
        .mdc-evolution-chip__text-label {
          color: mat.m2-get-color-from-palette($primary, 'pure-black');
        }
      }
    }
    &.mat-accent {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
      color: mat.m2-get-color-from-palette($accent, 'light-grey-50-contrast');
    }

    &.mat-mdc-standard-chip.na-chip-white {
      &:not(.mdc-evolution-chip--disabled) {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
        color: mat.m2-get-color-from-palette($accent, 'pure-white-contrast');
        .mdc-evolution-chip__text-label {
          color: mat.m2-get-color-from-palette($primary, 'pure-black');
        }
      }

      &:hover {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
        .mat-mdc-chip-focus-overlay {
          opacity: 0;
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-chip {
    @include mat.m2-typography-level($typography-config, 'body-1');

    &.na-small {
      @include mat.m2-typography-level($typography-config, 'body-2');

      $size-small: mat.m2-font-size($typography-config, 'icon-small');

      .mat-chip-remove.mat-icon {
        height: $size-small;
        width: $size-small;
        font-size: $size-small;
      }
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
