@use '@angular/material' as mat;
@use 'sass:map';

@mixin add-h5-and-h6($theme, $selector: '.mat-typography') {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-h5,
  #{$selector} h5 {
    @include mat.m2-typography-level($typography-config, 'h5');
  }

  .mat-h6,
  #{$selector} h6 {
    @include mat.m2-typography-level($typography-config, 'h6');
  }
}

@mixin reset-margins($selector: '.mat-typography') {
  .mat-h1,
  #{$selector} h1,
  .mat-h2,
  #{$selector} h2,
  .mat-h3,
  #{$selector} h3,
  .mat-h4,
  #{$selector} h4,
  .mat-h5,
  #{$selector} h5,
  .mat-h6,
  #{$selector} h6,
  #{$selector} p {
    margin: 0;
  }
}

@mixin link-typography($theme, $selector: '.mat-typography') {
  $typography-config: mat.m2-get-typography-config($theme);
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  a,
  #{$selector} a {
    color: mat.m2-get-color-from-palette($primary, 'default-blue');
    cursor: pointer;
    font-weight: mat.m2-font-weight($typography-config, 'body-2');
    text-decoration: none;

    &.disabled {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      pointer-events: none;
    }

    &.na-neutral {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
      font-weight: mat.m2-font-weight($typography-config, 'body-1');
    }
  }
}

@mixin markdown-typography($theme, $selector: '.mat-typography') {
  $typography-config: mat.m2-get-typography-config($theme);

  [markdown],
  .na-markdown {
    @include mat.m2-typography-level($typography-config, 'markdown-body');

    .mat-h1,
    #{$selector} h1,
    h1 {
      @include mat.m2-typography-level($typography-config, 'markdown-h1');

      &:not(:first-child) {
        padding-top: 20px;
      }
    }

    .mat-h2,
    #{$selector} h2,
    h2 {
      @include mat.m2-typography-level($typography-config, 'markdown-h2');

      &:not(:first-child) {
        padding-top: 16px;
      }
    }

    .mat-h3,
    #{$selector} h3,
    h3 {
      @include mat.m2-typography-level($typography-config, 'markdown-h3');

      &:not(:first-child) {
        padding-top: 14px;
      }
    }

    strong {
      @include mat.m2-typography-level($typography-config, 'markdown-body-strong');
    }

    p:not(:first-of-type) {
      margin-top: 18px;
    }
  }
}

@mixin init($theme) {
  @include reset-margins();

  @if $theme != null {
    @include add-h5-and-h6($theme);
    @include link-typography($theme);
    @include markdown-typography($theme);
  }
}
