@use 'variables' as variables;
@use 'sass:map';

// Toastr Overwrites
#toast-container {
  .toast-success {
    background-color: variables.$light-green;
    background-image: url('/assets/icons/notifications/success.svg');
  }

  .toast-warning {
    background-color: variables.$light-yellow;
    background-image: url('/assets/icons/notifications/alert.svg');
  }

  .toast-error {
    background-color: variables.$light-red;
    background-image: url('/assets/icons/notifications/error.svg');
  }

  .toast-success,
  .toast-warning,
  .toast-error {
    background-position: 5% 15px;
    color: variables.$pure-black;
  }

  .toast-title {
    font-size: map.get(variables.$h5, 'font-size');
    font-weight: map.get(variables.$h5, 'font-weight');
    line-height: map.get(variables.$h5, 'line-height');
    padding-bottom: 10px;
  }

  .toast-close-button {
    color: variables.$pure-black;
    font-family: 'Jost';
    font-size: map.get(variables.$h2, 'font-size');
    font-weight: 300;
    line-height: map.get(variables.$h2, 'line-height');
    margin-right: 5px;
    text-shadow: none;
  }
}

.toast-container .ngx-toastr {
  border-radius: 4px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

// Error blocks
.na-error-block {
  background-color: variables.$light-red;
  border-radius: 4px;
  margin: 40px 80px;
  padding: 20px;

  display: grid;
  grid-column-gap: 20px;
  grid-template-areas:
    'error-icon .'
    'error-icon .';
  grid-template-columns: min-content auto;

  p {
    margin-top: 10px;
  }

  .icon-error-cat {
    font-size: map.get(variables.$icon-big, 'font-size');
    grid-area: error-icon;
  }
}

.na-import-box-blue {
  background-color: variables.$light-blue;
  border-radius: 10px;
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  padding: 20px;

  .icon-info-outline {
    font-size: 24px;
  }

  a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }
}
