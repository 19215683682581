@use '@angular/material' as mat;
@use '../abstracts/variables' as variables;
@use './_datatable.columns' as columns;
@use './_datatable.filters' as filters;
@use './_datatable.cell' as cell;
@use './../styles.scss';
@use 'sass:map';

@mixin sizes-and-spacing() {
  navigatingart-data-table {
    display: block;
    span.na-default-resource-label {
      display: inline-grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      border-radius: 2px;
      padding: 3px 8px;
      font-size: map.get(variables.$caption, 'font-size');
      font-weight: map.get(variables.$caption, 'font-weight');
      line-height: map.get(variables.$caption, 'line-height');
      letter-spacing: 0.2px;
      background-color: variables.$light-green-50;
      color: variables.$pure-black;
    }
    .mat-mdc-table mat-row.mat-mdc-row,
    .mat-mdc-table mat-footer-row.mat-mdc-footer-cell {
      min-height: 120px;
    }

    .mat-mdc-table mat-header-row.mat-mdc-header-row {
      min-height: 40px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 100;
      border: 0;
    }

    .mat-mdc-cell {
      align-items: flex-start;
      padding: 20px 8px;
    }

    .mat-mdc-row {
      align-items: normal;
      min-height: 5rem;
    }

    &.na-small-rows {
      .mat-mdc-row {
        align-items: center;
        min-height: 20px;
      }

      .mat-mdc-cell {
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    &.na-condensed {
      .mat-mdc-table mat-row.mat-mdc-row,
      .mat-mdc-table mat-footer-row.mat-mdc-footer-cell {
        align-items: flex-start;
        min-height: 20px;
      }

      .mat-mdc-cell {
        align-items: center;
        padding-top: 13px;
        padding-bottom: 13px;
      }
    }

    &.na-condensed-padded {
      @extend .na-condensed;

      .mat-column-actions {
        margin-right: 20px;
      }
    }

    &.na-condensed-visibility {
      @extend .na-condensed-padded;

      .mat-mdc-cell {
        padding-top: 23px;
        padding-bottom: 13px;
      }

      mat-cell:first-of-type {
        padding-left: 0px;
      }
    }

    &.na-action-bar-active {
      padding-bottom: 80px;
    }

    &.na-full-page {
      navigatingart-data-table-filters {
        margin-top: 1rem;
      }

      .paginator-wrapper,
      navigatingart-data-table-filters {
        padding: 0 81px;
      }

      .paginator-wrapper {
        position: sticky;
        top: 0;
        z-index: 100;
      }

      .mat-mdc-row,
      .mat-mdc-header-row {
        padding: 0 81px;
      }

      .mat-mdc-table mat-header-row.mat-mdc-header-row {
        top: 40px;
      }

      mat-header-cell:first-of-type,
      mat-cell:first-of-type {
        padding-left: 0;
      }

      mat-header-cell:last-of-type,
      mat-cell:last-of-type {
        padding-right: 0;
      }
    }

    .recents {
      padding: 1rem 2.5rem;
      font-weight: map.get(variables.$body-2, 'font-weight');
      > div {
        margin-bottom: 1rem;
      }
      h6 {
        margin: 1.5rem 0;
      }
    }

    .paginator-wrapper {
      display: grid;
      grid-template-columns: min-content 1fr;

      > .na-settings-container {
        display: flex;
        justify-content: flex-end;

        .na-switch-view-container {
          display: flex;
          align-items: center;

          .na-switch-view-btn {
            padding: 0 6px;
            line-height: 18px;
            height: 18px;

            i {
              margin: 0px;
            }
          }
        }
      }

      > .clear-button-wrapper {
        margin-left: -14px;
      }

      > .mat-mdc-paginator {
        margin-right: -12px;
      }
    }

    &.na-datatable-align-label {
      .mat-mdc-row {
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        grid-template-rows: auto 0;
      }
    }

    .na-datatable-grid-container {
      padding: 50px 81px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 50px;

      .na-datable-grid-item-info {
        $padding-top: 25px;
        $padding-side: 20px;
        display: none;
        position: absolute;
        z-index: 10;
        height: calc(100% - (2 * $padding-top + 2 * $padding-side));
        width: calc(100% - (4 * $padding-side));
        opacity: 90%;
        padding: $padding-top $padding-side;
        cursor: pointer;
      }

      .na-grid-item-select {
        position: absolute;
        z-index: 11;
        margin-left: 5px;
        margin-top: 5px;
      }

      .na-grid-item-sub-text {
        margin-top: 5px;
      }

      .na-datable-grid-item {
        padding: 20px;
        max-height: 360px;

        &:hover {
          > .na-datable-grid-item-info {
            display: block;
          }
        }

        > img {
          object-fit: contain;
          width: 100%;
          max-height: 100%;
          object-position: left;
        }
      }
    }
  }

  navigatingart-block-header + navigatingart-data-table {
    mat-cell:first-of-type,
    mat-header-cell:first-of-type {
      padding-left: 0;
    }
  }

  navigatingart-block-header + navigatingart-data-table.na-datatable-inner-padding {
    mat-row,
    mat-header-row {
      padding-left: 30px;
    }
  }

  navigatingart-block-header + navigatingart-data-table:not(.na-bg-white) {
    margin-top: -2rem;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  mat-tab-body {
    navigatingart-data-table {
      mat-table.mat-mdc-table {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }
    }
  }

  mat-dialog-container {
    navigatingart-data-table {
      .mat-mdc-table {
        mat-row.mdc-data-table__row.selected {
          background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
        }
      }
    }
  }

  navigatingart-data-table {
    header {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
    }

    .mat-mdc-header-row {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
      color: mat.m2-get-color-from-palette($accent, 'dark-grey');
      .mdc-data-table__header-cell {
        color: inherit;
      }
    }

    //FIXME: Specific class for the datatable header row color in AgentRelationship imports. Remove it once the datatable is updated or once we migrate to material 3
    &.header-row-bg-white {
      .mat-mdc-header-row {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }
    }

    .na-group-label {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
      white-space: nowrap;
    }

    .switch-view-btn + .switch-view-btn {
      border-right: 1px solid mat.m2-get-color-from-palette($accent, 'dark-grey');
      border-radius: 0;
    }

    mat-row.mat-mdc-row,
    mat-footer-row.mat-mdc-footer-row {
      border-bottom: 1px solid mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }

    //FIXME: very hacky fix, we need to find a way to better remove the background color on hover, because it can easily cause problems in all of our datatables
    //removes hover background-color for not selected and selected rows
    .mat-mdc-table {
      mat-row.mdc-data-table__row:not(.selected):hover {
        background-color: transparent;
      }
      //FIXME: same very hacky fix, we need to find a way to better remove the background color on hover, because it can easily cause problems in all of our datatables
      //It can make a problem as we have two different datatables one is a small datatable like the list of agents with white background in that case we do not have any effect on cover
      //In the other case it is the datatable like select resource dialog where the selected resource shoud not have any background on hover because it has this grey overlay
      //However, at the moment in the angular material code, the background is set to inherit there, which means everytime when we hover, it overtakes the white background of the parent.
      //"unset" or "transparent does not do the trick unfortunately"
      mat-row.mdc-data-table__row:hover {
        background-color: transparent;
      }
      mat-row.mdc-data-table__row.selected {
        background-color: inherit;
      }
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      border-bottom: none;
    }

    .mat-sort-header-sorted {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }

    .mat-mdc-table {
      background-color: inherit;
      &.is-loading {
        .mat-mdc-row {
          opacity: 0.6;
          transition: all 0.2s ease-out;
          pointer-events: none;
        }
      }
    }

    .mat-mdc-cell {
      &.is-grouped {
        background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
      }
    }

    .recents {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
      h6 {
        color: mat.m2-get-color-from-palette($accent, 'dark-grey');
      }
    }

    .na-switch-view-container {
      .primary-color {
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }

      button.accent-color {
        color: mat.m2-get-color-from-palette($primary, 'default-blue');
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-tab-body {
    .mat-mdc-row {
      background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }
  }
  .na-datable-grid-item {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }
  }

  .na-grid-item-sub-text {
    color: mat.m2-get-color-from-palette($accent, 'dark-grey');
  }

  .na-datable-grid-item-info {
    background-color: #fff;
  }

  navigatingart-data-table-filters,
  .paginator-wrapper {
    background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
  }
}

navigatingart-data-table .na-datatable-grid-container .na-grid-item-select {
  right: 0;
  margin-right: 5px;
}

.na-datable-grid-item {
  position: relative;

  .grid-item-tag {
    position: static;

    &__item {
      margin-left: auto;
      margin-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(-5px, 5px);
      border-radius: 2px;
    }
  }

  .visibility-icon {
    position: relative;
    height: 2rem;

    .icon-private.na-icon-filled-light {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-1rem);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  navigatingart-data-table {
    .mat-mdc-header-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 8px;
      @include mat.m2-typography-level($typography-config, 'caption');

      .mat-mdc-icon-button {
        line-height: 1;
      }
    }

    .mat-mdc-icon-button.mat-primary {
      font-size: mat.m2-font-size($typography-config, 'button');
      font-weight: mat.m2-font-weight($typography-config, 'button');
      font-family: mat.m2-font-family($typography-config);
    }

    .mat-mdc-cell {
      @include mat.m2-typography-level($typography-config, 'body-1');
      &.is-sorted {
        font-weight: map.get(variables.$h6, 'font-weight');
      }
    }
    .na-datable-grid-item-info-text {
      font-weight: map.get(variables.$body-1, 'font-weight');
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @include filters.theme($theme);
  @include cell.theme($theme);

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
