@use 'variables';

@use '@angular/material' as mat;
@use 'sass:map';

@mixin sizes-and-spacing() {
  navigatingart-data-table-cell {
    display: inline-block;
    overflow: hidden;
    img {
      height: auto;
      max-width: 5rem;
      width: 100%;
    }
  }

  navigatingart-artwork-edit-events-list {
    mat-header-cell:first-of-type {
      padding-left: 0;
    }
  }

  //Hide actions when there are no actions defined in the config
  mat-header-cell.na-hide-actions,
  mat-cell.na-hide-actions {
    display: none;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');

  .mdc-data-table__pagination-total,
  .mdc-data-table__pagination-rows-per-page-label,
  .mdc-data-table__cell {
    color: mat.m2-get-color-from-palette($primary, 'pure-black');
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}

.cell-tag {
  background-color: map.get(variables.$na-accent-colors, 'light-green-50');
  padding: 0.2rem 0.4rem;
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.na-event-note-link {
  padding-bottom: 8px;
  text-transform: lowercase;
}
