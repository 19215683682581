@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  $checkbox-mark-color: mat.m2-get-color-from-palette($accent, 'pure-white');

  .mat-mdc-checkbox.mat-primary {
    color: mat.m2-get-color-from-palette($accent, 'dark-grey');

    &.na-checkbox-white {
      .mat-checkbox-inner-container {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }
    }

    &:hover {
      .mdc-checkbox__checkmark {
        border-color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
    }

    .mdc-label {
      line-height: map.get(variables.$icon-big, 'font-size');
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      border-color: mat.m2-get-color-from-palette($accent, 'dark-grey');
    }
  }

  .mdc-checkbox__checkmark {
    border-color: mat.m2-get-color-from-palette($accent, 'dark-grey');
  }

  // The color of the checkbox's checkmark / mixedmark.

  .mat-checkbox-checkmark {
    fill: $checkbox-mark-color;
  }

  .mat-checkbox-checkmark-path {
    // !important is needed here because a stroke must be set as an
    // attribute on the SVG in order for line animation to work properly.
    stroke: $checkbox-mark-color !important;
  }

  .mat-checkbox-mixedmark {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
  }

  .mat-mdc-checkbox-checked,
  .mat-checkbox-indeterminate {
    &.mat-primary {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }

    &.mat-primary .mat-checkbox-background {
      background-color: mat.m2-get-color-from-palette($primary, 'default-blue');
    }
  }

  .mat-checkbox-disabled.mat-primary {
    color: mat.m2-get-color-from-palette($accent, 'inactive-grey');

    &.mat-mdc-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }

      .mdc-label {
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
    }

    &:not(.mat-mdc-checkbox-checked) {
      .mdc-checkbox__checkmark {
        border-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }
    }
  }
}

@mixin sizes-and-spacing() {
  .mat-mdc-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        div.mdc-checkbox__background {
          top: 0;
          left: 0;
        }
      }
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();
  @if $theme != null {
    @include color($theme);
  }
}
