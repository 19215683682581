@use 'variables';
@use 'atomic';

.na-row-edit {
  padding-top: variables.$default-inner-margin;

  // add padding-top to the first row only by overriding the styling for all subsequent siblings
  & ~ .na-row-edit {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 16rem;
  }

  &.na-bg-white .na-block-edit {
    background-color: variables.$light-grey;
  }

  &.na-bg-grey .na-block-edit {
    background-color: #fff;
  }
}

.na-block-edit {
  @extend .na-inner-padding;
  min-height: 380px;
  padding-bottom: variables.$default-inner-margin;

  &.na-auto-height {
    min-height: auto;
  }
}

.na-block-edit-list {
  background-color: variables.$light-grey;
  margin: 0 40px;
  padding: variables.$default-inner-margin;

  .na-single-select-row {
    width: 100%;
    border-bottom: 1px solid #d3d1d1;
    align-items: center;
    padding: 0.7rem 0;
  }
}

.na-dialog-block-edit {
  background-color: white;
  border: variables.$default-inner-margin solid variables.$light-grey;
  margin: 0 40px;
  padding: variables.$default-inner-margin;

  &.na-no-margin {
    margin: 0;
  }

  &.na-no-padding {
    padding: 0;
  }
}

navigatingart-loading-indicator {
  margin: auto;
  width: 4rem;
  height: 4rem;
  top: 30vh;
  left: calc(50vw - 2rem);
}

.na-single-select-row {
  display: grid;
  grid-template-columns: auto min-content min-content;
  align-items: flex-start;

  .mat-mdc-icon-button:last-of-type {
    margin-left: 14px;
  }
}

.na-tombstone-event-label {
  color: variables.$dark-grey;
}

.na-simple-close-bar {
  justify-content: flex-end;
}

.na-item-close-bar {
  justify-content: space-between;
}

.na-datepicker {
  mat-hint {
    color: variables.$dark-grey;
  }
}
