// Task manager is using some special styles for the status labels right now
// We might refactor this in the future when more color coded label get introduced by UX

.task-manager-user-is-not-creator {
  .mat-column-actions button + button {
    cursor: default;
    pointer-events: none;
    mat-icon {
      color: var(--mat-sidenav-container-divider-color);
    }
  }
}
