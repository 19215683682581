@use 'variables';

$mask-size: 220px;

navigatingart-artwork-edit {
  navigatingart-preview-image {
    &:not(.na-empty-preview-image) {
      height: 300px;

      .na-preview-container {
        height: inherit;
        width: inherit;
        min-width: 480px;
        max-height: 300px;

        @media screen and (min-width: 768px) {
          min-width: 320px;
        }

        navigatingart-media-picture {
          min-width: inherit;
          max-height: inherit;
        }
      }
    }

    &.na-empty-preview-image {
      border: 1px dashed variables.$dark-grey;
    }
  }
}

navigatingart-preview-image,
navigatingart-entity-preview,
navigatingart-publication-preview {
  display: grid;
  justify-items: stretch;
  border: 1px solid variables.$light-grey;

  &:not(.na-hide-image) {
    justify-items: center;
    padding: 30px;
  }

  &.na-hide-image h6 {
    font-weight: normal;
  }

  .na-preview-container {
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    height: $mask-size;
    width: $mask-size;

    navigatingart-media-picture {
      max-height: $mask-size;
      max-width: $mask-size;
    }
  }

  &:not(.na-empty-preview-image) {
    .na-preview-container {
      .na-preview-btn {
        display: none;
      }

      &:hover .na-preview-btn {
        display: block;
      }
    }
  }

  .na-preview-btn {
    position: absolute;
  }
}
