@use 'sass:map';
@use '@angular/material' as mat;

@mixin sizes-and-spacing() {
  navigatingart-data-table-filters-input {
    display: block;
    .mat-mdc-form-field {
      width: 100%;

      .mat-mdc-form-field-flex {
        border-radius: 0;
        min-height: 52px;
      }

      .mdc-line-ripple {
        display: none;
      }
    }

    .mat-mdc-chip-set {
      margin: 3px 0 0px;
      display: block;
      .mdc-evolution-chip-set__chips {
        .mat-mdc-chip {
          margin: 0 0 1px;
        }
      }
    }

    .na-chip-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 10vw;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding: 0 0.2em 0 0.6em;
      }
    }

    .mat-mdc-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-hide-placeholder {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          padding-top: 18px;
        }
      }
    }
  }

  navigatingart-data-table-filter-input-double-value,
  navigatingart-data-table-filter-input-autocomplete-value,
  navigatingart-data-table-filter-dimension {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(2, 1fr);

    .mat-mdc-form-text-infix {
      width: auto;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent: map.get($color-config, 'accent');

  navigatingart-data-table-filters-input {
    .mat-mdc-form-field {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }

      .mat-mdc-form-field-focus-overlay {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
