@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-card.na-card-matric {
    padding: 15px 10px;
  }

  .na-card-metric-title .mat-mdc-card-subtitle {
    color: mat.m2-get-color-from-palette($primary, 'default-blue');
    font-size: mat.m2-font-size($typography-config, 'body-1');
    font-weight: mat.m2-font-weight($typography-config, 'caption');
    margin-bottom: 12px;
  }

  .na-card-metric-content {
    font-size: map.get(variables.$h3, 'font-size');
    font-weight: map.get(variables.$caption, 'font-weight');
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
  }
}
