@use 'sass:map';
@use './../mat-overwrites/typography/typography.imports' as na-typography;

$na-primary-colors: (
  50: #496fcf,
  100: #496fcf,
  200: #496fcf,
  300: #496fcf,
  400: #496fcf,
  500: #496fcf,
  600: #496fcf,
  700: #496fcf,
  800: #496fcf,
  900: #496fcf,

  light-blue: #e2ecff,
  default-blue: #496fcf,
  pure-black: #020509,
  contrast: (
    light-blue: #020509,
    default-blue: #fff,
    pure-black: #fff,
  ),
);

$na-accent-colors: (
  50: #f2f1ba,
  100: #f2f1ba,
  200: #f2f1ba,
  300: #f2f1ba,
  400: #f2f1ba,
  500: #f2f1ba,
  600: #f2f1ba,
  700: #f2f1ba,
  800: #f2f1ba,
  900: #f2f1ba,
  light-green-50: #f2f1ba,
  dark-grey: #949291,
  light-grey: #f7f5f4,
  light-grey-50: #fbfafa,
  inactive-grey: #d3d1d1,
  pure-white: #fff,
  contrast: (
    light-green-50: #020509,
    dark-grey: #020509,
    light-grey: #020509,
    light-grey-50: #020509,
    inactive-grey: #fff,
    pure-white: #020509,
  ),
);

$na-warn-colors: (
  50: #f2f1ba,
  100: #f2f1ba,
  200: #f2f1ba,
  300: #f2f1ba,
  400: #f2f1ba,
  500: #f2f1ba,
  600: #f2f1ba,
  700: #f2f1ba,
  800: #f2f1ba,
  900: #f2f1ba,
  light-green: #caeae6,
  light-red: #ffd4d4,
  light-yellow: #fff7b4,
  error-red: #ff7071,
  success-green: #4ebaaa,
  contrast: (
    light-green: #020509,
    light-red: #020509,
    light-yellow: #020509,
    error-red: #020509,
    success-green: #fff,
  ),
);

$default-blue: map.get($na-primary-colors, 'default-blue');
$pure-black: map.get($na-primary-colors, 'pure-black');
$light-blue: map.get($na-primary-colors, 'light-blue');
$light-green-50: map.get($na-accent-colors, 'light-green-50');
$dark-grey: map.get($na-accent-colors, 'dark-grey');
$light-grey: map.get($na-accent-colors, 'light-grey');
$light-grey-50: map.get($na-accent-colors, 'light-grey-50');
$inactive-grey: map.get($na-accent-colors, 'inactive-grey');
$light-green: map.get($na-warn-colors, 'light-green');
$light-red: map.get($na-warn-colors, 'light-red');
$light-yellow: map.get($na-warn-colors, 'light-yellow');
$error-red: map.get($na-warn-colors, 'error-red');
$success-green: map.get($na-warn-colors, 'success-green');
$pure-white: map.get($na-accent-colors, 'pure-white');

$h1: map.get(na-typography.$all-typography-config, 'headline');
$h2: map.get(na-typography.$all-typography-config, 'title');
$h3: map.get(na-typography.$all-typography-config, 'subheading-2');
$h4: map.get(na-typography.$all-typography-config, 'subheading-1');
$h5: map.get(na-typography.$all-typography-config, 'h5');
$h6: map.get(na-typography.$all-typography-config, 'h6');
$body-1: map.get(na-typography.$all-typography-config, 'body-1');
$body-2: map.get(na-typography.$all-typography-config, 'body-2');
$caption: map.get(na-typography.$all-typography-config, 'caption');
$caption-strong: map.get(na-typography.$all-typography-config, 'caption-strong');
$caption-small: map.get(na-typography.$all-typography-config, 'caption-small');
$icon-big: map.get(na-typography.$all-typography-config, 'icon-big');
$icon-medium: map.get(na-typography.$all-typography-config, 'icon-medium');
$icon-small: map.get(na-typography.$all-typography-config, 'icon-small');

$default-margin: 30px;
$default-inner-margin: 30px;
