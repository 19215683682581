@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?oaurxt');
  src:
    url('fonts/icomoon.eot?oaurxt#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?oaurxt') format('truetype'),
    url('fonts/icomoon.woff?oaurxt') format('woff'),
    url('fonts/icomoon.svg?oaurxt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-optimized-download:before {
  content: '\e991';
}
.icon-actions_menu:before {
  content: '\e900';
}
.icon-add-pd:before {
  content: '\e901';
}
.icon-add:before {
  content: '\e902';
}
.icon-agents:before {
  content: '\e903';
}
.icon-alert-cat:before {
  content: '\e904';
}
.icon-alert-filled .path1:before {
  content: '\e905';
  color: rgb(0, 0, 0);
}
.icon-alert-filled .path2:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-alert-filled .path3:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-alert-outline:before {
  content: '\e908';
}
.icon-alert-pd:before {
  content: '\e909';
}
.icon-archive:before {
  content: '\e90a';
}
.icon-arrow_diagonal:before {
  content: '\e90b';
}
.icon-arrow_right-copy:before {
  content: '\e90c';
}
.icon-arrow-down-cat:before {
  content: '\e90d';
}
.icon-arrow-north:before {
  content: '\e90e';
}
.icon-arrow-right-cat:before {
  content: '\e90f';
}
.icon-arrow-up-cat:before {
  content: '\e910';
}
.icon-articles:before {
  content: '\e911';
}
.icon-artworks-list:before {
  content: '\e912';
}
.icon-auction:before {
  content: '\e913';
}
.icon-Back_to_Top:before {
  content: '\e914';
}
.icon-backward-backward:before {
  content: '\e915';
}
.icon-backward:before {
  content: '\e916';
}
.icon-burger-menu:before {
  content: '\e917';
}
.icon-calendar:before {
  content: '\e918';
}
.icon-catalogue-reference:before {
  content: '\e919';
}
.icon-catalogue:before {
  content: '\e91a';
}
.icon-change-connection:before {
  content: '\e91b';
}
.icon-change-order:before {
  content: '\e91c';
}
.icon-chat:before {
  content: '\e91d';
}
.icon-check-cat:before {
  content: '\e91e';
}
.icon-check-pd:before {
  content: '\e91f';
}
.icon-checkbox_off .path1:before {
  content: '\e920';
  color: rgb(211, 209, 209);
}
.icon-checkbox_off .path2:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-checkbox_on .path1:before {
  content: '\e922';
  color: rgb(0, 0, 0);
}
.icon-checkbox_on .path2:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle_bg:before {
  content: '\e924';
}
.icon-circle_spinner:before {
  content: '\e925';
}
.icon-clear:before {
  content: '\e926';
}
.icon-close:before {
  content: '\e927';
}
.icon-collapse-cat:before {
  content: '\e928';
}
.icon-collapse-pd:before {
  content: '\e929';
}
.icon-collection-management:before {
  content: '\e92a';
}
.icon-collection:before {
  content: '\e92b';
}
.icon-column:before {
  content: '\e92c';
}
.icon-connect:before {
  content: '\e92d';
}
.icon-convert:before {
  content: '\e92e';
}
.icon-copyright-cat:before {
  content: '\e92f';
}
.icon-copyright-pd:before {
  content: '\e930';
}
.icon-delete:before {
  content: '\e931';
}
.icon-details:before {
  content: '\e932';
}
.icon-disconnect:before {
  content: '\e933';
}
.icon-down:before {
  content: '\e934';
}
.icon-download:before {
  content: '\e935';
}
.icon-drag:before {
  content: '\e936';
}
.icon-edit:before {
  content: '\e937';
}
.icon-email:before {
  content: '\e938';
}
.icon-error-cat:before {
  content: '\e939';
}
.icon-error-pd:before {
  content: '\e93a';
}
.icon-exhibition:before {
  content: '\e93b';
}
.icon-exit-fullscreen:before {
  content: '\e93c';
}
.icon-expand-cat:before {
  content: '\e93d';
}
.icon-expand-pd:before {
  content: '\e93e';
}
.icon-external-link:before {
  content: '\e93f';
}
.icon-fair-use:before {
  content: '\e940';
}
.icon-favorite:before {
  content: '\e941';
}
.icon-field:before {
  content: '\e942';
}
.icon-file_outline:before {
  content: '\e943';
}
.icon-file-cat:before {
  content: '\e944';
}
.icon-file-pd:before {
  content: '\e945';
}
.icon-filter-cat:before {
  content: '\e946';
}
.icon-filter-pd:before {
  content: '\e947';
}
.icon-first_page:before {
  content: '\e948';
}
.icon-format_align_left:before {
  content: '\e949';
}
.icon-forward-forward:before {
  content: '\e94a';
}
.icon-forward:before {
  content: '\e94b';
}
.icon-fullscreen:before {
  content: '\e94c';
}
.icon-grid_view:before {
  content: '\e94d';
}
.icon-grid:before {
  content: '\e94e';
}
.icon-help-cat:before {
  content: '\e94f';
}
.icon-help-pd:before {
  content: '\e950';
}
.icon-info-outline:before {
  content: '\e951';
}
.icon-info:before {
  content: '\e952';
}
.icon-information:before {
  content: '\e953';
}
.icon-items:before {
  content: '\e954';
}
.icon-jump_down:before {
  content: '\e955';
}
.icon-jump_right:before {
  content: '\e956';
}
.icon-last_page:before {
  content: '\e957';
}
.icon-list_view:before {
  content: '\e958';
}
.icon-live:before {
  content: '\e959';
}
.icon-loading_indicator .path1:before {
  content: '\e95a';
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-loading_indicator .path2:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-lock:before {
  content: '\e95c';
}
.icon-magnifier:before {
  content: '\e95d';
}
.icon-master-data:before {
  content: '\e95e';
}
.icon-media-cat:before {
  content: '\e95f';
}
.icon-media-pd:before {
  content: '\e960';
}
.icon-minus-cat:before {
  content: '\e961';
}
.icon-minus-pd:before {
  content: '\e962';
}
.icon-more:before {
  content: '\e963';
}
.icon-no-photo:before {
  content: '\e964';
}
.icon-object:before {
  content: '\e965';
}
.icon-order:before {
  content: '\e966';
}
.icon-orientation-minus-ninety:before {
  content: '\e967';
}
.icon-orientation-ninety:before {
  content: '\e968';
}
.icon-orientation-one-eighty:before {
  content: '\e969';
}
.icon-orientation-zero:before {
  content: '\e96a';
}
.icon-preview-img:before {
  content: '\e96b';
}
.icon-preview:before {
  content: '\e96c';
}
.icon-print:before {
  content: '\e96d';
}
.icon-private:before {
  content: '\e96e';
}
.icon-publications:before {
  content: '\e96f';
}
.icon-radio_button_off .path1:before {
  content: '\e970';
  color: rgb(255, 255, 255);
}
.icon-radio_button_off .path2:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(148, 146, 145);
}
.icon-radio_button_on .path1:before {
  content: '\e972';
  color: rgb(247, 245, 244);
}
.icon-radio_button_on .path2:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-radio_button_on .path3:before {
  content: '\e974';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-reload:before {
  content: '\e975';
}
.icon-report:before {
  content: '\e976';
}
.icon-revert:before {
  content: '\e977';
}
.icon-rotate:before {
  content: '\e978';
}
.icon-scans:before {
  content: '\e979';
}
.icon-search:before {
  content: '\e97a';
}
.icon-select:before {
  content: '\e97b';
}
.icon-series:before {
  content: '\e97c';
}
.icon-settings:before {
  content: '\e97d';
}
.icon-share:before {
  content: '\e97e';
}
.icon-skip:before {
  content: '\e97f';
}
.icon-slider_left .path1:before {
  content: '\e980';
  color: rgb(0, 0, 0);
}
.icon-slider_left .path2:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-slider_right .path1:before {
  content: '\e982';
  color: rgb(0, 0, 0);
}
.icon-slider_right .path2:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sorting_arrow_down:before {
  content: '\e984';
}
.icon-sorting_arrow_up:before {
  content: '\e985';
}
.icon-success:before {
  content: '\e986';
}
.icon-Text_Search:before {
  content: '\e987';
}
.icon-text-search:before {
  content: '\e988';
}
.icon-text:before {
  content: '\e989';
}
.icon-up:before {
  content: '\e98a';
}
.icon-upload:before {
  content: '\e98b';
}
.icon-visible:before {
  content: '\e98c';
}
.icon-zoom-in-pd:before {
  content: '\e98d';
}
.icon-zoom-in:before {
  content: '\e98e';
}
.icon-zoom-out-pd:before {
  content: '\e98f';
}
.icon-zoom-out:before {
  content: '\e990';
}
