@use '@angular/material' as mat;
@use 'sass:map';

$button-height: 38px;

@mixin sizes-and-spacing($theme) {
  .mat-mdc-icon-button.mat-primary {
    width: unset !important;
    height: unset !important;
    padding: 0;
    --mdc-icon-button-icon-size: 14px;
    vertical-align: center;
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button,
  .mat-mdc-button,
  .mat-mdc-unelevated-button.mat-default,
  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-outlined-button.mat-primary,
  .mat-mdc-button.mat-primary {
    height: $button-height;
    white-space: pre;

    mat-icon.mat-icon:first-child,
    i:first-child {
      margin-right: 7px;
      top: 2px;
    }

    mat-icon.mat-icon:last-child,
    i:last-child {
      margin-left: 7px;
      top: 2px;
    }

    .mdc-button__label {
      mat-icon.mat-icon:first-child,
      i:first-child {
        margin-right: 7px;
        top: 2px;
      }

      span.icon-arrow-down-cat {
        top: 2px;
      }

      mat-icon.mat-icon:last-child,
      i:last-child {
        margin-left: 7px;
        top: 2px;
      }
    }
  }

  .na-add-secondary {
    &.mat-mdc-button {
      margin-left: -20px;
    }
  }
}

@mixin square-btn($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  border-radius: 0;
  color: mat.m2-get-color-from-palette($accent, 'pure-white-contrast');
  height: 52px !important;
  min-width: 52px !important;
  padding: 0;

  // .mat-button-disabled is needed for specificity
  &:not(:disabled):hover {
    background-color: mat.m2-get-color-from-palette($primary, 'default-blue');
    color: mat.m2-get-color-from-palette($primary, 'default-blue-contrast');
  }
  &:disabled {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  button.mat-mdc-button:disabled,
  button.mdc-icon-button.mat-primary.mat-mdc-icon-button:disabled,
  button.mat-mdc-icon-button:disabled:disabled,
  button.mat-mdc-icon-button:disabled,
  button.mat-mdc-outlined-button:disabled:disabled,
  button.mat-mdc-icon-button:disabled {
    color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    border-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
  }

  a.mat-mdc-button,
  a.mat-icon-button,
  a.mat-mdc-icon-button,
  button.mdc-icon-button,
  button.mat-icon-button,
  button.mat-mdc-button,
  button.mat-mdc-icon-button,
  button.mat-mdc-outlined-button {
    background: inherit;
    color: inherit;
    white-space: pre;
    transition: none;
    //removes background color from hover effect;
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mat-icon-button-hover-state-layer-opacity: 0;
    --mat-text-button-hover-state-layer-opacity: 0;
    svg.mat-mdc-paginator-icon {
      fill: unset;
    }
    &.mat-primary {
      color: mat.m2-get-color-from-palette($primary, 'default-blue');
      border-radius: 0;
      //removes blue overlay from the buttons on hover effect
      --mat-mdc-button-persistent-ripple-color: unset;
    }

    &:hover {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    &:disabled {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }
  }

  .mat-mdc-outlined-button.mat-primary {
    border-style: solid;
    border-width: 2px;

    // .mat-button-disabled is required for extra specificity
    &:not(:disabled) {
      border-color: mat.m2-get-color-from-palette($primary, 'default-blue');

      &:hover {
        border-color: mat.m2-get-color-from-palette($primary, 'pure-black');

        a {
          color: mat.m2-get-color-from-palette($primary, 'pure-black');
        }
      }
    }
  }

  button.mat-mdc-unelevated-button,
  a.mat-mdc-unelevated-button {
    background-color: mat.m2-get-color-from-palette($primary, 'default-blue');
    color: mat.m2-get-color-from-palette($primary, 'default-blue-contrast');

    a {
      color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }

    // Added for specifity
    &.mat-primary {
      border-radius: 0;
    }

    &:hover {
      background-color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }
    &:disabled {
      background-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }

    &.na-add-btn {
      @include square-btn($theme);
      background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }

    &.na-clear-btn {
      @include square-btn($theme);
      &:not(:disabled) {
        background-color: mat.m2-get-color-from-palette($accent, 'light-grey-50');
      }
    }
  }

  a.mat-mdc-unelevated-button {
    color: mat.m2-get-color-from-palette($primary, 'default-blue-contrast');
    .mdc-button__label {
      vertical-align: middle;
    }
  }

  .na-kebab-button {
    color: mat.m2-get-color-from-palette($primary, 'pure-black');

    &:hover {
      color: mat.m2-get-color-from-palette($primary, 'default-blue');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mdc-icon-button,
  .mat-icon-button,
  .mat-mdc-icon-button.mat-primary {
    line-height: 1;

    i {
      font-size: mat.m2-font-size($typography-config, 'icon-big');
    }

    @include generate-icon-button-sizes(mat.m2-font-size($typography-config, 'icon-big'));
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    &.mat-primary a {
      font-size: mat.m2-font-size($typography-config, 'button');
      font-weight: mat.m2-font-weight($typography-config, 'button');
    }
  }

  button {
    &.mdc-button,
    &.mat-mdc-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-outlined-button {
      &.mat-primary {
        letter-spacing: 0.5px;
        line-height: mat.m2-line-height($typography-config, 'button');
      }
      .mat-icon {
        margin: 0 0;
      }
    }
  }

  //FIXME: try to find a better solution how to align left the "Add details for travelling venue" buttons or discuss with Hannah, if we need to align it at all
  navigatingart-artwork-edit-events-list {
    button {
      &.mdc-button,
      &.mat-mdc-button,
      &.mat-mdc-unelevated-button,
      &.mat-mdc-outlined-button {
        &.mat-primary {
          margin-right: auto;
        }
      }
    }
  }

  a.mat-mdc-unelevated-button {
    line-height: 32px;
  }
}

@mixin generate-icon-button-sizes($size) {
  mat-icon,
  .mat-mdc-icon-button mat-icon {
    font-size: $size;
    height: $size;
    width: $size;
    vertical-align: middle;
    margin-right: 4px;
  }

  i {
    vertical-align: middle;
    margin-right: 4px;
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include sizes-and-spacing($theme);
    @include color($theme);
    @include typography($theme);
  }
}
