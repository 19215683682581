@use '@angular/material' as mat;
@use 'sass:map';
@use 'abstracts/variables';
@use 'mat-overwrites/typography/typography.imports' as na-typography;
@use 'mat-overwrites/button' as na-button;
@use 'mat-overwrites/icon' as na-icon;
@use 'mat-overwrites/slide-toggle' as na-slide-toggle;
@use 'mat-overwrites/radio' as na-radio;
@use 'mat-overwrites/checkbox' as na-checkbox;
@use 'mat-overwrites/form-field' as na-form-field;
@use 'mat-overwrites/chips' as na-chips;
@use 'mat-overwrites/paginator' as na-paginator;
@use 'mat-overwrites/tabs' as na-tabs;
@use 'mat-overwrites/dialog' as na-dialog;
@use 'mat-overwrites/overlay' as na-overlay;
@use 'mat-overwrites/select' as na-select;
@use 'mat-overwrites/menu' as na-menu;
@use 'mat-overwrites/progress-bar' as na-progress-bar;
@use 'components/datatable' as na-datatable;
@use 'mat-overwrites/autocomplete' as na-autocomplete;
@use 'mat-overwrites/expansion-panel' as na-expansion-panel;
@use 'mat-overwrites/cards' as na-cards;

$na-primary-palette: mat.m2-define-palette(variables.$na-primary-colors, 'default-blue', 'light-blue', 'pure-black');
$na-warn-palette: mat.m2-define-palette(variables.$na-warn-colors);
$na-accent-palette: mat.m2-define-palette(variables.$na-accent-colors);

// Typography
$na-typography-config: na-typography.define-typography-config();

$na-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $na-primary-palette,
      accent: $na-accent-palette,
      warn: $na-warn-palette,
    ),
    typography: $na-typography-config,
    density: -1,
  )
);
// theme
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($na-light-theme);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
// @include mat.all-legacy-component-typographies($na-light-theme);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-typographies($na-light-theme);
@include na-typography.init($na-light-theme);
// Core theme
@include mat.elevation-classes();
@include mat.app-background();

// Material component themes (Loading of ALL components is currently disabled)
@include mat.tooltip-theme($na-light-theme);
@include mat.dialog-theme($na-light-theme);
@include mat.progress-bar-theme($na-light-theme);
@include mat.datepicker-theme($na-light-theme);

// Custom themes
@include mat.all-component-themes($na-light-theme);
@include na-button.theme($na-light-theme);
@include na-icon.theme($na-light-theme);
@include na-checkbox.theme($na-light-theme);
@include na-radio.theme($na-light-theme);
@include na-slide-toggle.theme($na-light-theme);
@include na-form-field.theme($na-light-theme);
@include na-chips.theme($na-light-theme);
@include na-datatable.theme($na-light-theme);
@include na-paginator.theme($na-light-theme);
@include na-tabs.theme($na-light-theme);
@include na-dialog.theme($na-light-theme);
@include na-overlay.theme($na-light-theme);
@include na-select.theme($na-light-theme);
@include na-menu.theme($na-light-theme);
@include na-progress-bar.theme($na-light-theme);
@include na-autocomplete.theme($na-light-theme);
@include na-expansion-panel.theme($na-light-theme);
@include na-cards.theme($na-light-theme);

// Enforce warn palette on invalid inputs
// https://navigatingart.atlassian.net/browse/DEV-620
:root {
  --mdc-filled-text-field-error-label-text-color: mat.m2-get-color-from-palette($na-warn-palette, 'default');

  --mdc-filled-text-field-error-hover-label-text-color: mat.m2-get-color-from-palette($na-warn-palette, 'default');

  --mdc-filled-text-field-error-focus-label-text-color: mat.m2-get-color-from-palette($na-warn-palette, 'default');
}

.mat-badge-accent {
  --mat-badge-background-color: #d4d01a;
  --mat-badge-text-color: black;
}
