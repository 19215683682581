@use '@angular/material' as mat;
@use 'variables' as variables;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-mdc-radio-button {
    &.na-inherit-label .mdc -label {
      align-items: inherit;
    }
  }
  .mat-mdc-radio-button {
    &.na-no-radio-padding .mdc-radio {
      padding: 0;
    }
  }

  .mdc-radio__outer-circle {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    border-color: mat.m2-get-color-from-palette($accent, 'dark-grey');
  }

  .mat-mdc-radio-button.mat-primary {
    color: mat.m2-get-color-from-palette($accent, 'dark-grey');
    .mdc-label {
      font-weight: map.get(variables.$h6, 'font-weight');
    }

    &.mat-mdc-radio-checked {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');

      .mdc-radio__outer-circle {
        background-color: mat.m2-get-color-from-palette($primary, 'default-blue');
        border-color: mat.m2-get-color-from-palette($primary, 'default-blue');
      }
    }

    .mdc-radio > .mdc-radio__background > .mdc-radio__inner-circle,
    &.mat-mdc-radio-checked {
      border-color: mat.m2-get-color-from-palette($accent, 'pure-white') !important;
    }

    &.mat-radio-disabled {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');

      .mdc-radio__outer-circle {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
        border-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }

      .mdc-radio__inner-circle {
        background-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }

      &.mat-mdc-radio-checked {
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }

      .mat-radio-label-content {
        color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }
    }
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
  }
}
