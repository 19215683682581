@use '@angular/material' as mat;
@use 'sass:map';
@use 'typography.variables' as na-typography-variables;

/**
 * Returns a collections of typography levels.
 *
 * This function is used as a replacement for the Material API function `define-typography-config`.
 */
@function define-typography-config($config: na-typography-variables.$all-typography-config) {
  @each $level in na-typography-variables.$mat-typography-levels-2014 {
    @if not map.has-key($config, $level) {
      @error 'Typography level #{$level} is missing from config';
    }
  }

  $font-family: map.get($config, 'font-family');

  @if ($font-family == null) {
    @error 'Font family is missing from typography config';
  }

  // Set base font-family to the config
  $new-config: (
    font-family: $font-family,
  );

  @each $key, $level in $config {
    @if ($key != 'font-family') {
      $new-level: map.merge(
        $level,
        (
          font-family: $font-family,
        )
      );
      $new-config: map.merge(
        $new-config,
        (
          $key: $new-level,
        )
      );
    }
  }
  @return $new-config;
}
