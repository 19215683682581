@use '@angular/material' as mat;
@use 'sass:map';

@mixin toggle-bar($theme) {
  // .mat-primary added for specificity
  .mat-mdc-slide-toggle.mat-primary {
    .mdc-switch {
      .mdc-switch__track {
        height: 20px;
        border-radius: 10px;
      }
      .mdc-switch__handle {
        height: 14px;
        width: 14px;
      }
      .mdc-switch__handle-track {
        top: 0;
        height: 14px;
        width: 14px;
        transform: translate3d(3px, 3px, 0);
      }

      &.mdc-switch--selected {
        .mdc-switch__handle-track {
          transform: translate3d(19px, 3px, 0);
        }
      }
    }
  }

  .na-event-toggle-unnumbered {
    margin-top: -25px;
    margin-bottom: 20px;
  }

  .na-event-toggle-questionable {
    margin-top: -5px;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-mdc-slide-toggle {
    .mdc-form-field {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }

    &:not(.mat-mdc-slide-toggle-checked) .mdc-form-field {
      color: mat.m2-get-color-from-palette($accent, 'dark-grey');
    }
  }

  // .mat-primary added for specificity
  .mat-mdc-slide-toggle.mat-primary {
    .mdc-switch {
      .mdc-switch__ripple {
        display: none;
      }

      &:enabled > .mdc-switch__track {
        &::before {
          background: mat.m2-get-color-from-palette($accent, 'inactive-grey');
        }
      }

      &.mdc-switch--unselected,
      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__icon {
            display: none;
          }

          .mdc-switch__shadow {
            box-shadow: none;
          }

          .mdc-switch__handle::after {
            background: mat.m2-get-color-from-palette($accent, 'pure-white');
          }

          &:hover:not(:focus):not(:active),
          &:active,
          &:focus,
          &:focus:not(:active) {
            .mdc-switch__handle::after {
              background: mat.m2-get-color-from-palette($accent, 'pure-white');
            }

            .mdc-switch__track::before {
              background: mat.m2-get-color-from-palette($accent, 'inactive-grey');
            }
          }
        }
      }

      .mdc-switch__handle {
        background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      }
    }

    button.mdc-switch.mdc-switch--selected:disabled,
    button.mdc-switch.mdc-switch--unselected:disabled {
      .mdc-switch__handle-track {
        .mdc-switch__handle::after,
        .mdc-switch__handle::before {
          background: mat.m2-get-color-from-palette($accent, 'pure-white');
        }
      }

      .mdc-switch__icons {
        opacity: 0;
      }

      .mdc-switch__track {
        background: mat.m2-get-color-from-palette($accent, 'inactive-grey');
        &::after,
        &::before {
          opacity: 0;
        }
      }
    }

    .mdc-switch--disabled + label {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
    @include toggle-bar($theme);
  }
}
