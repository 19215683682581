@use '@angular/material' as mat;
@use 'sass:map';

@mixin sizes-and-spacing() {
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-labels {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      .mat-mdc-tab:not(.mdc-tab--stacked) {
        height: 56px;
      }
    }

    .mat-mdc-tab {
      justify-content: flex-start;
      padding: 0 40px 0;
      .mdc-tab-indicator__content--underline {
        border: none;
      }
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-mdc-tab-label-container {
    .mat-mdc-tab {
      background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }

    .mdc-tab--active {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
    }

    .mdc-tab__content,
    .mat-mdc-tab:hover,
    .mat-mdc-tab.mdc-tab--active,
    .mat-mdc-tab.mdc-tab--active:focus {
      .mdc-tab__text-label {
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
    }

    .mat-mdc-tab {
      opacity: 1;
    }
  }

  .na-tabs-small {
    .mat-mdc-tab {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
    }

    .mdc-tab--active {
      background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-tab-label-container {
    .mdc-tab__content {
      @include mat.m2-typography-level($typography-config, 'subheading-2');
    }
  }

  .na-tabs-small {
    .mdc-tab__content {
      @include mat.m2-typography-level($typography-config, 'body-2');
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
