@use '@angular/material' as mat;
@use 'variables' as variables;
@use 'sass:map';

@mixin sizes-and-spacing() {
  mat-dialog-container.mat-mdc-dialog-container {
    padding: 0;

    .mdc-dialog__container {
      padding: 0;
    }
    .mat-mdc-dialog-content {
      margin: 0;
      padding: 0 1px 12px;
    }

    .mat-mdc-dialog-actions {
      & > button:last-child {
        margin-right: 2rem;
      }
    }

    navigatingart-block-header {
      & + .na-dialog-block-edit {
        border-top: 0;
      }
    }

    .na-block-edit {
      margin: 0 40px;
      min-height: 40px;
      margin-bottom: 30px;
      padding-bottom: 30px;
    }

    mat-tab-body.mat-mdc-tab-body {
      overflow: unset;

      .mat-tab-body-content {
        overflow: unset;
        height: max-content;
      }

      &.mat-tab-body-active {
        overflow-y: unset;
        overflow-x: unset;
      }
    }

    .mat-mdc-tab-group .mat-mdc-tab-body-wrapper {
      overflow: unset;
      mat-tab-body.mat-mdc-tab-body {
        overflow: unset;

        div.mat-mdc-tab-body-content {
          overflow: unset;
        }
      }
    }

    .mat-mdc-dialog-title {
      padding: 40px 40px 30px;
    }

    .mat-mdc-dialog-title {
      padding: 40px 40px 30px;
    }

    .mdc-dialog__surface {
      border-radius: 0;
    }
  }

  body .cdk-overlay-pane .mat-mdc-dialog-content {
    max-height: calc(100% - 185px);
    min-height: calc(100% - 185px);
  }

  .na-centered-dialog {
    mat-dialog-content.mat-mdc-dialog-content,
    mat-dialog-actions.mat-mdc-dialog-actions {
      padding: 0px 40px 30px;
      margin: 0;
    }
  }

  .na-full-width-dialog {
    mat-dialog-container {
      border-radius: 0;
    }
  }

  .na-event-edit-title {
    padding-bottom: 14px;
    border-bottom: 1px solid variables.$inactive-grey;
  }
}

.na-flex-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
  width: 100%;
}

mat-dialog-actions.na-action-bar,
.na-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 200;
  border-top: 1px solid variables.$inactive-grey;

  &.na-entity-list-aligned {
    padding: 1rem 81px;
  }
}

mat-dialog-actions.mat-mdc-dialog-actions {
  margin-bottom: 0;
  gap: 10px;
  padding: 8px 0;
  justify-content: unset;
  .na-action-bar {
    @extend .na-action-bar;
    padding-left: 2.5rem;
    padding-right: 2.8rem;
  }
}

.na-header-bar {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  top: 0;
}

.na-flex-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-mdc-dialog-container {
    .mdc-dialog__content {
      color: mat.m2-get-color-from-palette($primary, 'pure-black') !important;
    }
  }

  .mat-mdc-dialog-title {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
  }

  mat-dialog-actions {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    bottom: 0;
    position: sticky;
    z-index: 200;
  }

  mat-dialog-actions.na-action-border {
    border-top: 1px solid variables.$inactive-grey;
  }

  // Disable surrounding buttons when inline editing is active
  mat-dialog-content {
    &.na-disable-buttons {
      .mat-mdc-icon-button.mat-primary {
        color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
        pointer-events: none;
      }
    }
  }
}

.na-dialog-close-btn-container {
  .na-dialog-close-btn {
    position: absolute;
    top: 8px;
    z-index: 1001;
    right: 8px;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  mat-dialog-container.mat-mdc-dialog-container {
    .mdc-dialog__content {
      @include mat.m2-typography-level($typography-config, 'body-1');

      &.na-dialog-padding {
        margin-bottom: 30px;
      }
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include typography($theme);
    @include color($theme);
  }
}

.na-event-detail-container {
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0px;
  }
}
.na-event-detail-content {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid variables.$inactive-grey;
  padding-bottom: 12px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }

  .na-detail-buttons {
    display: flex;
    gap: 20px;
  }
}

.na-event-detail-sub {
  @extend .na-event-detail-content;
  display: grid;
  grid-template-columns: 25px 1fr max-content;
  gap: 16px;
}

.na-edit-form {
  padding: 30px;
}
