@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';

@mixin sizes-and-spacing() {
  .mat-mdc-form-field {
    display: block;
    width: 100%;
    .mat-mdc-form-field-infix:has(.mdc-floating-label--float-above) {
      padding-top: 18px;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          // padding-top: 18px;
          padding-bottom: 3px;
          min-height: 10px;
        }
      }
    }

    .mat-mdc-form-field-flex {
      display: inline-flex;
    }

    .mat-mdc-form-field-icon-prefix,
    .mdc-text-field {
      padding: 0 0 0 0;
    }

    .mat-mdc-form-field-text-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          min-height: unset;
        }
      }
    }

    .mat-mdc-form-field-text-prefix mat-icon {
      margin-right: 5px;
    }

    &.mat-form-field-should-float {
      .mat-mdc-floating-label {
        font-size: map.get(variables.$body-2, 'font-size');
      }
    }

    .mat-mdc-input-element {
      bottom: 0px;

      &.mat-mdc-chip-input {
        bottom: 0;
        flex: 1 0 50px;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    }
  }

  //adds custom styling to the form field in the block
  navigatingart-block-header {
    .mat-mdc-form-field {
      width: 300px;
      .mdc-line-ripple {
        opacity: 0;
      }
    }
  }

  .na-small .mat-mdc-form-field,
  .mat-form-field.na-small {
    width: 5.6rem;

    .mat-mdc-form-field-infix {
      padding-bottom: 0.3rem;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  $warn: map.get($color-config, 'warn');

  $underline-color: mat.m2-get-color-from-palette($accent, 'dark-grey');
  $label-color: mat.m2-get-color-from-palette($accent, 'dark-grey');
  $focused-label-color: mat.m2-get-color-from-palette($primary, 'default-blue');
  $inactive-color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
  $warn-color: mat.m2-get-color-from-palette($warn, 'error-red');

  // mat-form-field supports 4 different appearance variants (set via the appearance input).
  // The legacy appearance is the default style.
  // mat-form-field selector added for specificity
  .mat-mdc-form-field {
    &.mat-form-field-disabled label.mat-mdc-floating-label {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }

    .mdc-text-field {
      position: relative;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }

    .mat-mdc-form-field-error {
      color: unset;
    }
    .mdc-text-field--disabled.mdc-text-field--filled,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: inherit;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: inherit;
    }

    .mat-mdc-floating-label {
      color: $label-color;
      // margin-top: -0.5em;
    }

    .mdc-text-field__input,
    .mdc-text-field--invalid .mdc-text-field__input,
    .mdc-text-field--focused,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled),
    .mdc-text-field--invalid.mdc-text-field--disabled {
      caret-color: mat.m2-get-color-from-palette($primary, 'pure-black');
      color: unset;
      border-bottom-color: $underline-color !important;
      ::after {
        border-bottom-color: $underline-color !important;
      }
      ::before {
        border-bottom-color: $underline-color !important;
      }
    }
    .mdc-line-ripple {
      // left: 8px;
      position: absolute;
      width: 100%;
      top: 100%;
      pointer-events: none;
      transform: scale3d(1, 1.0001, 1);
      color: unset;
      border-bottom-color: $underline-color !important;
      ::after {
        border-bottom-color: $underline-color;
      }
      ::before {
        border-bottom-color: $underline-color;
      }
    }

    &.mat-focused {
      .mat-mdc-floating-label {
        color: $focused-label-color;
      }

      .mdc-line-ripple {
        background-color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }

      .mat-form-field-ripple {
        height: 1px;
        background-color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
    }

    &.mat-form-field-disabled {
      .mat-mdc-floating-label {
        color: $label-color;

        &.mat-form-field-empty {
          color: $inactive-color;
        }
      }

      .mdc-line-ripple {
        background-image: none;
        background-color: $inactive-color;
      }
    }

    &.mat-form-field-invalid {
      color: $warn-color;

      .mat-mdc-floating-label {
        color: $warn-color;
      }
    }
  }

  .na-input-disabled-no-line {
    .mdc-line-ripple {
      display: none;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
    @include mat.m2-typography-level($typography-config, 'body-1');
  }

  mat-select.mat-mdc-select,
  .mat-mdc-select-placeholder,
  mat-option.mat-mdc-option,
  div > .mat-mdc-form-field-input-control.mdc-text-field__input {
    @include mat.m2-typography-level($typography-config, 'body-1');
  }
  .na-small .mat-mdc-form-field,
  .mat-mdc-form-field.na-small {
    @include mat.m2-typography-level($typography-config, 'caption');
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
