@use 'sass:map';
@use '@angular/material' as mat;

@mixin sizes-and-spacing() {
  navigatingart-data-table-filter-complex-indexing-terms {
    display: grid;
    grid-template-columns: 1fr 4fr;

    .mat-mdc-form-field-text-prefix {
      align-self: center;
    }

    &.na-no-dropdown {
      display: block;
    }
  }

  .na-search-overlay {
    &.mat-mdc-autocomplete-panel {
      max-height: unset;
      padding: 0;
    }
  }

  .na-search-overlay-select-list {
    max-height: 240px;
    overflow: scroll;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent: map.get($color-config, 'accent');

  .na-search-overlay-select-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 0.2px solid mat.m2-get-color-from-palette($accent, 'inactive-grey');
    padding: 5px 0;
  }

  .na-search-overlay-select-list {
    border-top: 0.2px solid mat.m2-get-color-from-palette($accent, 'inactive-grey');
  }

  navigatingart-data-table-filter-complex-indexing-terms {
    input::placeholder {
      color: black;
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
  }
}
