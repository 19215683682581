@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-autocomplete-panel {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
  }
}
