@use '@angular/material' as mat;
@use 'sass:map';

// List of typography level keys as defined in the 2014 Material Design Specification

$mat-typography-levels-2014: (
  'display-4',
  'display-3',
  'display-2',
  'display-1',
  'headline',
  'title',
  'subheading-2',
  'subheading-1',
  'body-1',
  'body-2',
  'caption',
  'button',
  'input'
);

/**
 * Material typography config map
 *
 * Should contain ALL typographic properties defined in the Material 2014 specification.
 * Mirrors the map created by the Material API function `define-typography-config`.
 *
 * The levels `display-4`, `display-3`, `display-2`, `display-1` and `input` are added for
 * consistency with the default Material typography config. They contain the default values.
 */

$mat-typography-config: (
  font-family: 'Jost',
  headline: mat.m2-define-typography-level(38px, 42px, 600, $letter-spacing: 0.004em),
  // h1
  title: mat.m2-define-typography-level(32px, 36px, 600),
  // h2
  subheading-2: mat.m2-define-typography-level(22px, 26px, 600),
  // h3
  subheading-1: mat.m2-define-typography-level(19px, 23px, 600),
  // h4
  body-1: mat.m2-define-typography-level(15px, 18px, 400),
  body-2: mat.m2-define-typography-level(14px, 18px, 400),
  caption: mat.m2-define-typography-level(12px, 16px, 500),
  button: mat.m2-define-typography-level(15px, 18px, 600),
  display-4: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400),
  input: mat.m2-define-typography-level(inherit, 1.125, 400),
);

// Custom typography config map
$custom-typography-config: (
  h5: mat.m2-define-typography-level(18px, 20px, 600),
  h6: mat.m2-define-typography-level(15px, 18px, 600),
  caption-strong: mat.m2-define-typography-level(12px, 16px, 500),
  // duplicates caption
  caption-small: mat.m2-define-typography-level(10px, 14px, 500),
  icon-big: mat.m2-define-typography-level(24px, 24px, 400),
  icon-medium: mat.m2-define-typography-level(18px, 18px, 400),
  icon-small: mat.m2-define-typography-level(14px, 14px, 400),
  markdown-h1: mat.m2-define-typography-level(23px, 28px, 700, $letter-spacing: 0.2px),
  markdown-h2: mat.m2-define-typography-level(21px, 26px, 700, $letter-spacing: 0.2px),
  markdown-h3: mat.m2-define-typography-level(19px, 24px, 700, $letter-spacing: 0.2px),
  markdown-body: mat.m2-define-typography-level(15px, 18px, 400, $letter-spacing: 0.2px),
  markdown-body-strong: mat.m2-define-typography-level(15px, 18px, 600),
  markdown-label: mat.m2-define-typography-level(10px, 14px, 500),
);

// Merged map with both typography configs
$all-typography-config: map.merge($mat-typography-config, $custom-typography-config);
