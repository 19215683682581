@use 'sass:map';
@use '@angular/material' as mat;
@use './_datatable.filters-input' as filters-input;
@use './_datatable.complex-filters' as complex-filters;

@mixin sizes-and-spacing() {
  navigatingart-data-table-filters {
    display: block;

    .filters {
      display: grid;
      gap: 0 30px;
      grid-template-columns: repeat(3, 1fr) 52px;
      grid-auto-columns: 1fr;

      .filter {
        display: grid;
        grid-template-columns: 1fr auto;
        overflow: hidden;
      }
    }
  }

  mat-dialog-content {
    navigatingart-data-table-filters {
      padding: 30px 40px;
    }

    .paginator-wrapper {
      padding: 0 40px;
    }

    .mat-mdc-paginator {
      margin-right: 24px;
    }
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include filters-input.theme($theme);
    @include complex-filters.theme($theme);
    @include sizes-and-spacing();
  }
}
