@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-mdc-menu-panel {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');

    &.mat-menu-below,
    &.mat-menu-above {
      min-height: 0;
    }

    & > .mat-mdc-menu-content:not(:empty) {
      padding: 0;
      z-index: 1001;
      max-height: 240px;
    }
  }

  button.mat-mdc-menu-item {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');

    &:hover,
    &:not([disabled]):hover {
      background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-option-text {
    @include mat.m2-typography-level($typography-config, 'body-1');
  }

  .mat-mdc-menu-panel {
    .mat-mdc-menu-content,
    .mat-mdc-menu-content > .mat-mdc-menu-item > .mdc-list-item__primary-text {
      --mat-menu-item-label-text-size: 15px;
      --mat-menu-item-label-text-weight: 400;
      --mat-menu-item-label-text-line-height: 18px;
    }
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
