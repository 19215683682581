@use 'variables';
@use '@angular/material' as mat;
@use 'sass:map';

.na-bg-white {
  background-color: #fff;
}

.na-primary-color {
  color: variables.$pure-black !important;
}

.na-accent-color {
  color: variables.$default-blue !important;
}

.na-bg-grey {
  background-color: variables.$light-grey;
}

.na-text-grey {
  color: variables.$dark-grey;
}

.na-text-italic {
  font-style: italic;
}

.na-error {
  color: variables.$error-red;
}

.na-page-padding {
  padding: 0 81px;
}

.na-page-padding-small {
  // FIXME: Only used in archive-edit right now
  padding: 0 41px;
}

.na-inner-margin {
  margin-left: variables.$default-inner-margin;
  margin-right: variables.$default-inner-margin;
}

.na-inner-padding {
  padding-left: variables.$default-inner-margin;
  padding-right: variables.$default-inner-margin;
}

.na-inner-padding-vertical {
  padding-top: variables.$default-inner-margin;
  padding-bottom: variables.$default-inner-margin;
}

.na-padding-top-50 {
  padding-top: 50px;
}

.na-hidden {
  display: none;
}

.na-centered {
  text-align: center;
}

.na-private {
  color: variables.$dark-grey;
}

.na-tooltip-multiline {
  white-space: pre-line;
}

.na-inactive {
  color: variables.$inactive-grey;
}

.na-bg-inactive {
  background-color: variables.$inactive-grey;
}

//FIXME it seems that the read-only attribute is applied nowhere. Check it and if not, delete this styling, as it leads to the weird behavior of the MDC components. see below
input:read-only {
  cursor: not-allowed;
}

//FIXME mat-radio-button in the recents of the datatable of the resource select dialog and the checkboxes of the select media dialog has read-only attribute applied for no reason
//this style overwrites this weird bug
mat-checkbox,
mat-radio-button {
  input:read-only {
    cursor: pointer;
  }
}

@media print {
  .na-no-print {
    display: none;
  }
}

.na-small-label {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: variables.$dark-grey;
}

.na-padding-10 {
  padding: 10px;
}

.na-padding-30 {
  padding: 30px;
}

.na-padding-right-10 {
  padding-right: 10px;
}

.na-padding-top-10 {
  padding-top: 10px;
}

.na-margin-left-10 {
  margin-left: 10px;
}

.na-margin-top-30 {
  margin-top: 30px;
}

.na-margin-top-20 {
  margin-top: 25px;
}

.-na-margin-left-30 {
  margin-left: -30px;
}

.na-margin-horizontal-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.na-margin-horizontal-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.na-margin-vertical-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.na-flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.na-flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.na-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.na-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.na-width-full {
  width: 100%;
}

.na-width-auto {
  width: auto;
}

.na-margin-0 {
  margin: 0;
}

.na-border-left {
  border-left: 1px solid variables.$dark-grey;
}

.na-border-right {
  border-right: 1px solid variables.$dark-grey;
}

.na-border-box {
  box-sizing: border-box;
}

.na-align-self-center {
  align-self: center;
}

.na-grow {
  flex-grow: 1;
}

.na-bg-grey-light {
  background-color: #ebebee;
}
