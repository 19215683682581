@use 'sass:map';

$container-max-widths: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$container-margins: (
  xs: 0px,
  sm: 1rem,
  md: 2rem,
  lg: 3rem,
  xl: 4rem,
  xxl: 5rem,
) !default;

$grid-gaps-x: (
  xs: 20px,
  sm: 20px,
  md: 20px,
  lg: 30px,
  xl: 30px,
  xxl: 40px,
) !default;

$grid-gaps-y: (
  xs: 30px,
  sm: 30px,
  md: 40px,
  lg: 40px,
  xl: 40px,
  xxl: 60px,
) !default;

$grid-gaps-y-dialog: (
  xs: 10px,
  sm: 10px,
  md: 15px,
  lg: 15px,
  xl: 25px,
  xxl: 25px,
) !default;

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &.align-center {
    align-items: center;
  }

  .justify-self-end {
    justify-self: end;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    @media screen and (min-width: $container-max-width) {
      $gap-x: map.get($grid-gaps-x, $breakpoint);
      $gap-y: map.get($grid-gaps-y, $breakpoint);
      gap: $gap-y $gap-x;

      @for $span from 1 through 12 {
        .span-#{$breakpoint}-#{$span} {
          display: block;
          grid-column: auto / span $span;

          @for $start from 1 through 12 {
            &-start-#{$start} {
              grid-column: $start / span $span;
            }
          }
        }

        .span-#{$breakpoint}-hidden {
          display: none;
        }
      }
    }

    &.no-gap {
      gap: 0;
    }

    &.form-gap {
      gap: 0 3rem;
    }
  }
}

.na-slim-row-gap {
  row-gap: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container {
  .grid {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @media screen and (min-width: $container-max-width) {
        $gap-x: map.get($grid-gaps-x, $breakpoint);
        $gap-y: map.get($grid-gaps-y-dialog, $breakpoint);
        gap: $gap-y $gap-x;
      }
    }
  }
}
