@use '@angular/material' as mat;
@use 'variables' as variables;
@use 'sass:map';

@mixin sizes-and-spacing() {
  mat-progress-bar.mat-mdc-progress-bar {
    border-radius: 5px;
    height: 10px;
    margin: 1rem 0;
    & > div {
      height: 100%;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent: map.get($color-config, 'accent');

  mat-progress-bar .mdc-linear-progress__buffer {
    background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include color($theme);
    @include sizes-and-spacing();
  }
}
