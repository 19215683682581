@use 'variables';
@use 'sass:map';

.na-media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  gap: 40px;
}

.na-media-block {
  display: flex;
  justify-content: center;

  &.na-media-background {
    background-color: variables.$light-grey-50;
  }

  &.na-media-small {
    max-height: 85px;
    .icon-private {
      font-size: map.get(variables.$icon-small, 'font-size');
      margin-left: 4px;
    }

    navigatingart-media-picture {
      height: 80px;
      max-height: 80px;
      max-width: 80px;
    }
  }

  .icon-private {
    margin-bottom: 8px;
    margin-left: 15px;
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: variables.$light-blue;
    border-radius: 50%;
    color: variables.$pure-black;
    font-size: map.get(variables.$icon-medium, 'font-size');
    padding: 5px;
  }

  navigatingart-media-picture {
    align-items: center;
    display: flex;
    height: 220px;
    max-height: 220px;
    max-width: 220px;
  }
}

.na-container-upload {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  grid-template-rows: 1fr 1fr;
  align-items: stretch;
  justify-items: stretch;

  > .na-button-upload {
    background-color: white;
    border: 2px dashed variables.$inactive-grey;
    color: variables.$default-blue;
    cursor: pointer;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content min-content;
    justify-items: center;
    align-content: center;
    padding-bottom: variables.$default-inner-margin;
    padding-top: variables.$default-inner-margin;
    font-weight: map.get(variables.$h6, 'font-weight');

    &:hover {
      color: variables.$pure-black;
    }

    &-disabled {
      color: variables.$inactive-grey;
      cursor: default;

      &:hover {
        color: variables.$inactive-grey;
      }
    }
  }
}
