@use '@angular/material' as mat;
@use 'sass:map';

@mixin sizes-and-spacing() {
  .mat-mdc-paginator-container {
    margin-right: -0.2rem;
  }

  .mat-mdc-paginator-page-size {
    align-items: center;
    padding-right: 10px;
    margin-right: 10px;
    width: 125px;
  }

  .mat-mdc-paginator-range-label {
    margin: 0;
  }
  .mat-mdc-paginator-navigation-next,
  .mat-mdc-paginator-navigation-previous {
    width: 32px;
  }

  .mat-mdc-paginator-icon {
    width: 24px;
  }

  mat-form-field.mat-mdc-paginator-page-size-select {
    margin: 0;

    .mat-mdc-select-trigger {
      width: 40px;
    }
    width: 40px;

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    .mat-mdc-form-text-infix {
      padding: 0;
      border: 0;
    }

    .mdc-line-ripple {
      display: none;
    }
    //removes the outline of the page number
    .mdc-notched-outline {
      display: none;
    }

    .mat-mdc-select-value {
      max-width: 4rem;
    }

    .mat-mdc-form-text-infix {
      margin-top: 4px;
      width: auto;
    }

    .mat-mdc-select-value {
      width: 2rem;
    }

    .mat-mdc-select-arrow-wrapper {
      height: 0;

      .mat-mdc-select-arrow {
        margin: 0;
        padding-right: 0;
      }
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  background: none !important;
  .mat-mdc-paginator-outer-container {
    color: mat.m2-get-color-from-palette($primary, 'pure-black');
    .mat-mdc-select-arrow {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }
  }

  .mat-mdc-paginator-page-size {
    align-items: center;
    border-right: 1px solid mat.m2-get-color-from-palette($accent, 'dark-grey');
    height: 22px;
  }

  .mat-mdc-paginator {
    .mat-mdc-paginator-page-size {
      padding-left: 10px;
      height: 25px;
      border-left: 1px solid mat.m2-get-color-from-palette($accent, 'dark-grey');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-paginator-page-size {
    @include mat.m2-typography-level($typography-config, 'caption');
  }

  .mat-mdc-paginator-page-size-label {
    white-space: nowrap;
  }

  .mat-mdc-paginator-range-label {
    @include mat.m2-typography-level($typography-config, 'caption');
    &:before {
      display: inline-block;
    }
  }
}

@mixin theme($theme) {
  .mat-mdc-paginator {
    @include sizes-and-spacing();

    @if $theme != null {
      @include color($theme);
      @include typography($theme);
    }
  }
}
