@use 'variables';
@use 'sass:map';

@mixin visibility-icon-in-tables {
  flex: 0 25px;

  navigatingart-data-table-cell {
    overflow: visible;
  }

  .icon-private {
    background-color: variables.$light-blue;
    border-radius: 50%;
    color: variables.$pure-black;
    font-size: map.get(variables.$icon-small, 'font-size');
    padding: 5px;
  }
}

navigatingart-data-table {
  .mat-column-selection {
    flex: 0 0 auto;
    width: 60px;
    margin-left: 20px;

    .mat-mdc-radio-disabled {
      opacity: 0.5;
    }
  }

  .mat-column-actions {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-shrink: 3;
    gap: 14px;

    &.mat-mdc-header-cell {
      align-items: center;
    }
  }

  .mat-mdc-header-cell {
    // Grouped columns headers
    div,
    p.na-group-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mat-sort-header-content {
      display: block;
    }
  }

  .mat-column-aucTitle,
  .mat-column-pubTitle,
  .mat-column-detTitle,
  .mat-column-title,
  .mat-column-agentSelection,
  .mat-column-artist,
  .mat-column-buyer,
  .mat-column-content,
  .mat-column-dimensions,
  .mat-column-levelOfDescription,
  .mat-column-note,
  .mat-column-seller,
  .mat-column-fileName,
  .mat-column-venuesDate,
  .mat-column-oldValues,
  .mat-column-newValues {
    flex-grow: 2;
  }

  .mat-column-AgentName,
  .mat-column-entityTitle,
  .mat-column-authorNames,
  .mat-column-medium,
  .mat-column-venuesTitle,
  .mat-column-exhTitle {
    flex-grow: 2;
  }

  .mat-column-date {
    flex-shrink: 2;
  }

  .mat-column-operation,
  .mat-column-entity,
  .mat-column-entityId {
    flex-shrink: 3;
    max-width: 6rem;
  }

  .mat-column-detCreationYear,
  .mat-column-evDateMin,
  .mat-column-evDateMax {
    flex-grow: 1;
    white-space: nowrap;
  }

  .mat-column-changes {
    flex-shrink: 2;
  }

  .mat-column-id {
    flex-shrink: 1;
    max-width: 5rem;
  }

  .mat-column-seqNum,
  .mat-column-evDateMin,
  .mat-column-evDateMax {
    flex: 0 0 80px;
  }

  .mat-column-publication,
  .mat-column-thumbnail {
    max-width: 8rem;
  }

  .mat-column-noteType {
    justify-content: center;
  }

  .mat-column-primary {
    justify-content: flex-end;
  }

  .mat-column-publishers,
  .mat-column-authorNames {
    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .mat-column-otherIdNameAndValue {
    span.name {
      color: variables.$dark-grey;
    }
  }

  .mat-column-details-link-icon {
    flex: 0 0 30px;
  }

  .mat-column-icon {
    max-width: 80px;
    min-width: 80px;
  }

  mat-row.na-hide-extract-media {
    mat-cell.mat-column-actions {
      button:first-of-type {
        visibility: hidden;
      }
    }
  }
}

navigatingart-artwork-edit-events-list {
  .mat-column-actions {
    margin-right: 40px;
  }
}

navigatingart-artwork-event-edit-dialog {
  .mat-column-agentSelection {
    cursor: pointer;
  }
}

navigatingart-agent-names-list {
  .mat-column-primaryFlag {
    max-width: 5rem;
  }
  .mat-column-references {
    max-width: 8rem;
  }
}

navigatingart-agents-list-block,
navigatingart-artwork-edit-events-list,
navigatingart-artwork-events-list,
navigatingart-note-list,
navigatingart-other-id-list,
navigatingart-locations-list-block,
navigatingart-markings-list-block,
navigatingart-publication-media-manager,
.navigatingart-marking-selections-list-block,
navigatingart-object-at-location-list {
  .mat-column-visibilityFlag {
    @include visibility-icon-in-tables;
  }
}

navigatingart-publication-media-manager .mat-column-actions {
  flex: 0 45px;
  padding: 0 !important;
}

navigatingart-publication-media-manager .mat-column-visibilityFlag {
  box-sizing: content-box;
}

.mat-column-venueVisibilityFlag {
  @include visibility-icon-in-tables;
}

.na-condensed {
  .mat-column-actions {
    max-width: 140px; // make action space smaller to allow room for text
  }
}

// Used in DataTableAvailableConfigs.ArtworkEditExhibitionArtEventList as row class for highlight icons
.mat-mdc-row.no-highlight {
  mat-cell.mat-column-actions {
    button:nth-child(1) {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }
}

// Used in DataTableAvailableConfigs.IndexingTermsList as row class to hide the edit button for getty items
.na-hide-edit {
  button:first-of-type {
    visibility: hidden;
  }
}

navigatingart-data-table.hide-highlight-action {
  mat-cell.mat-column-actions {
    button:first-of-type {
      visibility: hidden;
    }
  }
}

navigatingart-data-table.artworks-events-provenance {
  mat-row {
    padding-left: variables.$default-margin;
  }
}

// Used in DataTableAvailableConfigs.ArtworkEditExhibitionArtEventList as row class to indent exhibitions and venues
navigatingart-data-table.artworks-events-exhibition {
  mat-row.exhibition {
    // margin-left: variables.$default-margin;

    &.no-venues {
      margin-left: 0;
      padding-left: variables.$default-margin;
    }
  }

  mat-row.exhibition {
    & + .exhibition-venue {
      mat-cell.mat-column-content > navigatingart-data-table-cell:before {
        content: 'Other venues:';
        font-weight: map.get(variables.$h6, 'font-weight');
        color: variables.$pure-black;
        line-height: 2rem;
      }
      mat-cell.mat-column-actions {
        align-self: end;
      }
    }
  }

  mat-row.exhibition-venue {
    margin-left: variables.$default-margin;
    padding-left: variables.$default-margin;
    // Hide highlight button for venue events
    button:first-of-type {
      visibility: hidden;
    }
  }

  mat-row.exhibition-venue {
    &.last-venue {
      padding-left: variables.$default-margin + variables.$default-margin;
      margin-left: 0;
    }
  }

  mat-row.exhibition-venue + .exhibition-venue {
    padding-left: variables.$default-margin;
    margin-left: variables.$default-margin;
    &.last-venue {
      padding-left: variables.$default-margin + variables.$default-margin;
      margin-left: 0;
    }
  }
}

navigatingart-data-table.global-notes-list {
  .mat-column-noteType {
    justify-content: initial;
  }
}

navigatingart-data-table.taskmanager-task-list {
  .mat-column-title,
  .mat-column-project,
  .mat-column-associatedEntity {
    flex-grow: 2;
  }

  .mat-column-assignee {
    flex-grow: 2;
  }

  .mat-mdc-cell {
    margin-top: 4px;
  }

  .mat-mdc-cell.mat-column-status {
    margin-top: 0;
  }
}
