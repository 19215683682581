@use 'abstracts/variables';
@use 'abstracts/grid';
@use 'abstracts/atomic';
@use 'components/entity-edit';
@use 'components/dragdrop';
@use 'components/media';
@use 'components/preview-media';
@use 'components/notifications';
@use 'components/artwork-event-list';
@use '_task-manager';
@use 'sass:map';

html,
body {
  height: 100%;
  position: relative;
}

body {
  margin: 0;

  * {
    position: relative;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 100%;
}

::-webkit-scrollbar-thumb {
  background-color: variables.$dark-grey;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  min-height: 20px;
}

navigatingart-root,
navigatingart-authenticated {
  display: block;
  // overflow: hidden;
}

a {
  &.na-navigation-item {
    color: variables.$pure-black;
    font-weight: map.get(variables.$h6, 'font-weight');
    min-height: 32px;
    margin-right: 36px;
    white-space: nowrap;

    &.is-active {
      color: variables.$default-blue;
    }

    i {
      font-size: map.get(variables.$icon-big, 'font-size');
      margin-right: 5px;
      top: 5px;
    }
  }
}

// FIXME: Discuss loading indicator styling
navigatingart-loading-indicator {
  display: block;
  transform: scale(4);
  left: 0;
  right: 0;
  margin: auto;
  top: 30%;
  z-index: 101;
}

//FIXME: overwrite styles of the paginator select panel
div.cdk-overlay-pane {
  div.mat-mdc-select-panel {
    min-width: 130px;
  }
}

//FIXME: it overwrites the standard behavior of the mdc forms where the floating label dissappears under the density which is lower than -1
//makes sure that the floating label appears under the density which is lower than -1
//We should revisit this issue once the angular team fixes this
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline;
}

//FIXME: overrites the standard behavior of the mdc dialog where the dialog is not full width
.cdk-global-overlay-wrapper .cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: unset;
  min-width: unset;
}

//FIXME: overwrites the standard height and width of the mdc checkbox input for better clickability
.mdc-checkbox input.mdc-checkbox__native-control {
  width: 20px;
  height: 20px;
}

//FIXME: overwrites the standard position of the mdc checkbox input in the changeStatusDialog of the archive tree component
.na-change-archive-status-form .mdc-checkbox .mdc-checkbox__native-control {
  right: 2px;
}

.na-external-resource-label {
  display: inline-block;
  background-color: variables.$pure-black;
  color: variables.$pure-white;
  font-size: map.get(variables.$body-2, 'font-size');
  font-weight: map.get(variables.$body-2, 'font-weight');
  padding: 3px 7px 3px 7px;
  border-radius: 3px;
}
