@use '@angular/material' as mat;
@use 'sass:map';

@mixin sizes-and-spacing() {
  .mat-form-field-appearance-fill {
    .mat-mdc-select-trigger {
      .mat-mdc-select-arrow-wrapper {
        transform: none;
      }
    }
  }

  .mat-mdc-select {
    pointer-events: all;
    .mat-mdc-select-trigger {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr min-content;
      align-items: center;

      .mat-mdc-select-value {
        display: inline-block;
        max-width: none;

        i {
          margin-right: 8px;
          vertical-align: bottom;
        }
      }
    }
  }

  navigatingart-data-table-filters {
    .mat-mdc-select {
      .mat-mdc-select-arrow-wrapper {
        .mat-mdc-select-arrow {
          margin: 0;
          // top: 20%;
          padding-right: 16px;
        }
      }
    }

    .mat-form-field-appearance-fill {
      .mat-mdc-select-trigger {
        .mat-mdc-select-arrow-wrapper {
          transform: none;
          transform: translateY(-4px);
        }
      }
    }
  }

  .na-small {
    .mat-select-arrow {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid;
    }
  }

  .na-title-select {
    .mat-mdc-select .mat-select-trigger {
      grid-template-columns: min-content 1fr;
      gap: 1rem;
    }
  }

  // Overlay is not inside mat-select element
  .mat-mdc-select-panel {
    max-height: 200px;

    mat-option.mat-mdc-option {
      height: 40px;
    }
  }

  .na-type-select,
  .na-title-select,
  .na-accent-select,
  .na-primary-select,
  .na-neutral-select {
    display: inline-block;

    .mat-mdc-form-field {
      .mdc-line-ripple {
        display: none;
      }

      .mat-mdc-form-field-subscript-wrapper {
        position: absolute;
        top: 0;
        height: 0;
      }
    }
  }

  .na-type-select {
    min-width: 420px;

    .mat-select-arrow-wrapper {
      align-self: start;
    }

    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 7px 30px;
      }
      .mat-mdc-form-text-infix {
        padding-bottom: 0px;
      }
    }
  }

  .na-accent-select,
  .na-primary-select,
  .na-neutral-select {
    max-width: 138px;

    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 7px 16px;
        border-radius: 4px;
      }
      .mat-mdc-form-field-infix,
      .mat-mdc-form-text-infix {
        padding: 0px;
        min-height: 10px;
        border-top: none;
        .mat-mdc-floating-label.mdc-floating-label {
          display: none;
        }
      }
    }
  }

  .na-primary-select {
    max-width: 126px;
  }

  mat-option.mat-mdc-option {
    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  $warn: map.get($color-config, 'warn');

  mat-select.mat-mdc-select,
  .mat-mdc-select-placeholder,
  mat-option.mat-mdc-option,
  div > .mat-mdc-form-field-input-control.mdc-text-field__input {
    color: mat.m2-get-color-from-palette($primary, 'pure-black');
    .mat-mdc-select-arrow {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }
  }
  .mat-mdc-form-field {
    mat-select.mat-mdc-select.mat-mdc-select-invalid {
      .mat-mdc-select-arrow {
        color: mat.m2-get-color-from-palette($warn, 'error-red');
      }

      .mat-mdc-select-placeholder {
        color: mat.m2-get-color-from-palette($warn, 'error-red');
      }
    }
  }

  .mat-mdc-select {
    &.mat-select-disabled {
      .mat-select-arrow-wrapper {
        color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
      }
    }
  }

  // Overlay is not inside mat-select element
  .mat-mdc-select-panel.mat-primary {
    background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);

    mat-option.mat-mdc-option {
      &.mat-mdc-selected {
        background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
    }
  }

  div.cdk-overlay-pane {
    .mat-mdc-option:focus.mdc-list-item,
    .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
      background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
      .mdc-list-item__primary-text {
        color: mat.m2-get-color-from-palette($primary, 'pure-black');
      }
      .mat-pseudo-checkbox {
        display: none;
      }
    }
    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }

    .fake-enabled-option-unchecked-wrapper {
      cursor: pointer;
      &:hover {
        background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
      }
      .mat-mdc-option.mdc-list-item--disabled {
        .mdc-list-item__primary-text {
          opacity: unset;
          .mat-mdc-checkbox.mat-primary {
            .mdc-label {
              color: mat.m2-get-color-from-palette($accent, 'dark-grey');
            }
          }
        }
      }
    }

    .fake-enabled-option-checked-wrapper {
      cursor: pointer;
      &:hover {
        background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
      }
      .mat-mdc-option.mdc-list-item--disabled {
        .mdc-list-item__primary-text {
          opacity: unset;
        }
      }
    }
  }

  .mat-mdc-option {
    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
    }
  }

  .na-accent-select {
    .mat-mdc-form-field {
      background-color: mat.m2-get-color-from-palette($accent, 'light-green-50');
      border-radius: 4px;
    }
  }

  .na-primary-select {
    .mat-mdc-form-field {
      background-color: mat.m2-get-color-from-palette($primary, 'light-blue');
      border-radius: 4px;
    }
  }

  .na-neutral-select {
    .mat-mdc-form-field {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
      border-radius: 4px;
    }
  }

  .na-type-select {
    .mat-mdc-form-field {
      background-color: mat.m2-get-color-from-palette($accent, 'pure-white');
      .mat-mdc-floating-label {
        color: mat.m2-get-color-from-palette($primary, 'default-blue');
      }
    }
  }

  .mat-mdc-option[aria-disabled='true'] {
    pointer-events: none;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-select-value i {
    font-size: mat.m2-font-size($typography-config, 'icon-medium');
  }

  .na-type-select,
  .na-title-select {
    //changes typography when select element is used like a title. Like in the cse of the archival resource

    mat-select.mat-mdc-select,
    .mat-mdc-select-placeholder,
    mat-option.mat-mdc-option,
    div > .mat-mdc-form-field-input-control.mdc-text-field__input {
      @include mat.m2-typography-level($typography-config, 'subheading-2');
    }
  }

  .na-primary-select,
  .na-accent-select,
  .na-neutral-select {
    .mat-mdc-select-value-text > mat-select-trigger,
    .mat-mdc-select-min-line {
      text-transform: uppercase;
      @include mat.m2-typography-level($typography-config, 'button');
    }

    .mat-select-value {
      @include mat.m2-typography-level($typography-config, 'button');
    }
  }
}

@mixin theme($theme) {
  @include sizes-and-spacing();

  @if $theme != null {
    @include color($theme);
    @include typography($theme);
  }
}
