@use '@angular/material' as mat;
@use 'sass:map';

@mixin icon-fonts($typography-config) {
  $size-big: mat.m2-font-size($typography-config, 'icon-big');
  $size-medium: mat.m2-font-size($typography-config, 'icon-medium');
  $size-small: mat.m2-font-size($typography-config, 'icon-small');

  font-size: $size-medium;
  height: $size-medium;
  width: $size-medium;
  line-height: 1;

  &.na-big {
    font-size: $size-big;
    height: $size-big;
    width: $size-big;
  }

  &.na-small {
    font-size: $size-small;
    height: $size-small;
    width: $size-small;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  $warn: map.get($color-config, 'warn');

  .mat-icon {
    &.mat-primary {
      color: mat.m2-get-color-from-palette($primary, 'default-blue');
    }

    &.na-icon-inactive {
      color: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    }
  }

  [class*='na-icon-filled'] {
    border-radius: 50%;
    padding: 5px;
  }

  .na-icon-filled {
    background: mat.m2-get-color-from-palette($primary, 'default-blue');
    color: mat.m2-get-color-from-palette($primary, 'default-blue-contrast');
  }

  .na-icon-filled-inactive {
    background: mat.m2-get-color-from-palette($accent, 'inactive-grey');
    color: mat.m2-get-color-from-palette($accent, 'inactive-grey-contrast');
  }

  .na-icon-filled-light {
    background: mat.m2-get-color-from-palette($primary, 'light-blue');
    color: mat.m2-get-color-from-palette($primary, 'light-blue-contrast');
  }

  .na-icon-filled-warning {
    background: mat.m2-get-color-from-palette($warn, 'light-red');
    color: mat.m2-get-color-from-palette($primary, 'pure-black');
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  // Icons as prefix in form fields
  .mat-mdc-form-field .mat-mdc-form-field-text-suffix .mat-mdc-icon-button mat-icon.mat-icon,
  .mat-form-field .mat-mdc-form-field-text-suffix mat-icon.mat-icon {
    @include icon-fonts($typography-config);
  }

  // Icons as suffix in form fields
  .mat-mdc-form-field .mat-mdc-form-field-text-prefix .mat-mdc-icon-button mat-icon.mat-icon,
  .mat-form-field .mat-mdc-form-field-text-prefix mat-icon.mat-icon {
    @include icon-fonts($typography-config);
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include typography($theme);
    @include color($theme);
  }
}
