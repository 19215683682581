@use 'variables';
@use 'sass:map';

.na-event {
  border-bottom: 1px solid variables.$inactive-grey;

  .na-event-item:first-child {
    padding-top: 20px;
  }

  .na-event-item:last-child {
    padding-bottom: 10px;
  }
}

.na-event-item {
  display: grid;
  grid-template-columns: 25px 25px 1fr max-content;
  align-items: flex-start;
  min-height: 2rem;
  gap: 10px;

  .icon {
    i {
      color: variables.$default-blue;
      font-size: map.get(variables.$icon-big, 'font-size');
    }
  }

  .na-event-item-info-icons {
    display: flex;
    gap: 10px;
  }

  .actions {
    button {
      margin-left: 5px;
    }
  }

  .na-align-visibility {
    margin-top: 3px;
  }
  &-child {
    @extend .na-event-item;
    grid-template-columns: 25px 1fr max-content;
  }
}

.na-drag-item {
  .text {
    cursor: move;
  }
}

.na-not-highlighted {
  opacity: 0.5;
  filter: grayscale(100%);
}

.na-event-rendered-content {
  display: inline;
}

.na-note-marker {
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border: solid 1px variables.$inactive-grey;
  color: variables.$inactive-grey;
  border-radius: 50%;
  text-align: center;
  margin-left: variables.$default-margin * 0.2;
  cursor: default;
}

.na-warning-indicator {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding: 3px 5px 5px;
  cursor: default;
  &::after {
    content: '!';
  }
}
