@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';

@mixin override-bg {
  // unfortunately needed to avoid being overridden by Material
  &.na-bg-white {
    background-color: white;
  }

  &.na-bg-grey {
    background-color: variables.$light-grey;
  }
}

@mixin sizes-and-spacing($theme) {
  .mat-expansion-panel {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded {
      height: unset;
    }
  }

  .mat-expansion-panel-header {
    padding: 0;

    navigatingart-block-header {
      width: 100%;
    }
  }

  .na-no-expansion-padding {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  .na-additional-block {
    padding-bottom: variables.$default-inner-margin;

    .mat-expansion-panel-body {
      padding: 0 5px 35px;
    }

    .mat-expansion-panel-header {
      padding: 0 5px;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  mat-expansion-panel {
    @include override-bg();

    .mat-expansion-indicator::after {
      color: mat.m2-get-color-from-palette($primary, 'pure-black');
    }
    // globally override new Material styles
    &.mat-expansion-panel:not([class*='mat-elevation-z']) {
      border-radius: 0;
      box-shadow: none;
    }

    &.mat-expansion-panel:not(.mat-expanded) {
      .mat-expansion-panel-header:not([aria-disabled='true']):hover {
        @include override-bg();
      }
    }
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    &.na-bg-grey {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey');
    }
  }

  mat-expansion-panel.na-additional-block {
    border: 1px solid mat.m2-get-color-from-palette($accent, 'inactive-grey');

    &.na-block-edit.mat-expansion-panel {
      background-color: white;
    }
  }

  mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background-color: white;
    }
  }

  mat-expansion-panel.na-bg-grey {
    background-color: mat.m2-get-color-from-palette($accent, 'light-grey');

    mat-expansion-panel-header:hover {
      background-color: mat.m2-get-color-from-palette($accent, 'light-grey') !important;
    }
  }
}

@mixin theme($theme) {
  @if $theme != null {
    @include sizes-and-spacing($theme);
    @include color($theme);
  }
}
