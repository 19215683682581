@use 'variables';
@use 'sass:map';

.cdk-drag-preview {
  background-color: white;
  border: 2px dotted variables.$inactive-grey;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
